import React, { useState, useEffect } from "react";
import "./App.css";
import { Container, Row, Col } from "react-grid-system";
import {
    NotificationContainer,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Form from "./form/Form";
import FilePackageList from "./file-package-list/filePackageList";
import getClientId from "./client-uploader/getClientId";
import { loadReCaptcha } from 'react-recaptcha-v3'

const App = () => {
    const [uploaderId, setUploaderId] = useState(null);
    const [update, setUpdate] = useState(false);

    const storage = window.localStorage;

    const successHandler = async formData => {
        if (uploaderId) {
            storage.setItem("formData", JSON.stringify(formData));
        }
        setUpdate(!update);
    };

    const errorHandler = () => {

    };

    const getFormData = () => {
        const data = storage.getItem("formData");
        if (!data) {
            return {
                name: "",
                mail: "",
                phone: "",
                description: "",
                contact: "graweb"
            };
        }
        return JSON.parse(data);
    };

    useEffect(() => {
        (async () => {
            const data = await getClientId();
            setUploaderId(data);
        })();
        loadReCaptcha("6LdKfOcUAAAAAMIAdX7pWoymtbmt569YRtrJ2taO");
    }, []);

    return (
        <div>
            <Container fluid>
                <Row justify="around" className="">
                    <Col sm={12} md={6} className="app">
                        <div className="flex-responsive rng-background">
                            <h1><span className="dark-text">Graweb</span> Uložiště</h1>
                            <a href="https://tiskarnahustopece.cz/images/graweb-jak-%20na-tiskova-data.pdf" target="_blank" rel="noopener noreferrer">
                                Jak na tisková data
                            </a>
                        </div>
                        <div>
                            <h3>Nahrát soubory</h3>
                            <Form
                                clientUploader={uploaderId}
                                onSuccess={successHandler}
                                onError={errorHandler}
                                formDefaults={getFormData()}
                            />
                        </div>
                        <div>
                            <h3>Již nahrané</h3>
                            <FilePackageList uploaderId={uploaderId} update={update}/>
                        </div>
                    </Col>
                </Row>
            </Container>
            <NotificationContainer />
        </div>
    );
};

export default App;
